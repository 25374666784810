<template>
  <div style="display: flex; justify-content: center; padding-top: 10px;width: 100vw;height: 100vh;">
    <div v-if="!href"
      style="position: fixed;right: 2px;top: 2px; color: #fff;z-index: 99999;background:rgba(0,0,0,0.3);padding: 2px 5px;border-radius: 20px;">
      点击此处分享
      <img src="../../assets/proin.jpg" style="width: 20px;" alt="">
    </div>
    <div id="printDiv" style="width: 100vw;height: 100vh;overflow: auto;">
      <div :style="`position: relative;width:100vw;height:100vw;page-break-after:always`">
        <div v-for="(item, index) in entity.printlist?.slice(0, 1)" style="text-align: center;margin: 20px 0;"
          :key="item.Id">
          <span v-html="item.value" style="font-weight: 600; color: #000;"></span>
        </div>
        <el-descriptions class="margin-top" size="small" :column="1" border>
          <el-descriptions-item :key="item.Id" v-for="(item, index) in entity.printlist?.slice(1, 10)">
            <template slot="label">
              {{ item.value.split(':')[0] }}
            </template>
            <span v-html="item.value.split(':')[1]"></span>
          </el-descriptions-item>
        </el-descriptions>
        <div v-for="(item, index) in entity.printlist?.slice(10, 11)" style="text-align: center;margin: 20px 0;"
          :key="item.Id">
          <span v-html="item.value"></span>
        </div>
        <el-table :data="tableData" style="width: 100vw;margin: 20px 0;" border>
          <el-table-column v-for="i in columns" :key="i.dataIndex" :label="i.title" :prop="i.dataIndex" width="180">
          </el-table-column>
        </el-table>
        <el-descriptions class="margin-top" size="small" :column="1" border style="padding-bottom: 50px;">
          <el-descriptions-item :key="item.Id" v-for="(item, index) in entity.printlist?.slice(11)">
            <template slot="label">
              {{ item.value.split(':')[0] }}
            </template>
            <span v-html="item.value.split(':')[1]"></span>
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
    <div v-if="href" class="printBox">
      <div :style="{
        minWidth: '100vw',
      }">
        <a :href="href">
          <el-button style="width: 100%;background: #2a9fe4; color: #fff;" type="default">去 签 收 </el-button>
        </a>
        <!-- <el-button type="primary" v-print="'printDiv'">打印</el-button> -->
      </div>
    </div>
  </div>
</template>

<script>
import { table } from 'console';
import wx from "weixin-js-sdk";

const NumFormatting = function (data, num = 2) {
  if (isNaN(Number(data))) {
    return data;
  } else {
    return Number(data).toFixed(num);
  }
};
const downloadFile = (url, downloadName = "") => {
  // console.log(url);
  const link = document.createElement("a");
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => {
      link.href = URL.createObjectURL(blob);
      // console.log(link.href)
      link.download = downloadName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
};
function jsgetDPI() {
  var arrDPI = new Array();
  if (window.screen.deviceXDPI != undefined) {
    arrDPI[0] = window.screen.deviceXDPI;
    arrDPI[1] = window.screen.deviceYDPI;
  } else {
    var tmpNode = document.createElement("DIV");
    tmpNode.style.cssText =
      "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
    document.body.appendChild(tmpNode);
    arrDPI[0] = parseInt(tmpNode.offsetWidth);
    arrDPI[1] = parseInt(tmpNode.offsetHeight);
    tmpNode.parentNode.removeChild(tmpNode);
  }
  return arrDPI;
}
export default {
  data() {
    return {
      entity: {},
      htmlTitle: '对账函',
      comSeal: "",
      cusSeal: "",
      CreateTime: "",
      Id: "",
      newarr: [],
      columns: [],
      tableData: [],
      href: '',
      billid: '',
    };
  },
  async mounted() {
    this.billid = this.$route.query?.Id;
    console.log(this.$route.query);
    this.href = this.$route.query?.href;
    this.$http
      .post(
        "/Base/Base_Reconciliation/GetRecDetail?Id=" + this.$route.query.Id
      )
      .then((res) => {
        console.log(res);
        if (res.Success) {
          this.Id = res.Data.Id;
          this.CreateTime = res.Data.CreateTime.substring(0, 10);
          this.comSeal = res.Data.CompanySeal;
          this.cusSeal = res.Data.CustomerSeal;
          this.entity = JSON.parse(res.Data.SpanContent);
          console.log(this.entity);
          if (res.Data.Content) {
            if (res.Data.Imgs) {
              this.imglist = res.Data.Imgs.split(",");
            } else {
              this.imglist = [];
            }
            this.$nextTick(() => {
              var obj = JSON.parse(res.Data.Content);
              this.printPages = obj.printPages;
              this.styleW = obj.styleW;
              this.styleH = obj.styleH;
              this.printlist = obj.printlist;
              this.printsize = obj.printsize;
              this.isAccessory = obj.isAccessory;
              if (this.printsize === "portrait") {
                this.styleText =
                  '<style type="text/css" media="print">\n' +
                  "  @page { size: portrait; }\n" +
                  "</style>";
              } else {
                this.styleText =
                  '<style type="text/css" media="print">\n' +
                  "  @page { size: landscape; }\n" +
                  "</style>";
              }
              this.data = this.printlist.filter((item) => {
                return item.type === "4";
              });
              var tablearr = JSON.parse(JSON.stringify(this.data));
              var arr = [];
              if (tablearr[0].rowCount) {
                for (
                  var i = 0;
                  i < tablearr[0].datalist.length;
                  i += tablearr[0].rowCount
                ) {
                  console.log(
                    tablearr[0].datalist.slice(i, i + tablearr[0].rowCount),
                    "数据"
                  );
                  arr.push(
                    tablearr[0].datalist.slice(i, i + tablearr[0].rowCount)
                  );
                }
              } else {
                if (obj.keyType === "A4") {
                  for (var i = 0; i < tablearr[0].datalist.length; i += 10) {
                    arr.push(tablearr[0].datalist.slice(i, i + 10));
                  }
                } else {
                  for (var i = 0; i < tablearr[0].datalist.length; i += 5) {
                    arr.push(tablearr[0].datalist.slice(i, i + 5));
                  }
                  console.log(arr);
                }
              }

              var newarr = [];

              // arr.forEach((item) => {
              //   var newobj = JSON.parse(res.Data.Content);
              //   this.entity.printlist.forEach((val) => {
              //     if (val.Key == "发货日期" && val.value) {
              //       this.cTime = val.value;
              //     }
              //     if (val.type === "4") {
              //       val.datalist = [];
              //       this.$set(val, "datalist", item);
              //       let length = val.datalist.length;
              //       console.log(val);
              //       if (val.rowCount) {
              //         if (length < val.rowCount) {
              //           for (let i = 0; i < val.rowCount - length; i++) {
              //             val.datalist.push({});
              //           }
              //         }
              //       } else {
              //         if (obj.keyType === "A4") {
              //           if (length < 10) {
              //             for (let i = 0; i < 10 - length; i++) {
              //               val.datalist.push({});
              //             }
              //           }
              //         } else {
              //           if (length < 5) {
              //             for (let i = 0; i < 5 - length; i++) {
              //               val.datalist.push({});
              //             }
              //           }
              //         }
              //       }
              //       console.log(val.datalist, "datalist");
              //     }
              //   });
              //   newarr.push(newobj);
              // });
              this.printPages = arr.length;
              this.newarr = newarr[0];
              console.log(this.newarr, this.entity)
              let tableProps = this.entity.printlist.filter(item => item.type == '4')
              this.columns = tableProps[0].columns
              console.log(this.columns, 123)
              this.tableData = tableProps[0].datalist
            });
          } else {
            this.$message.error("暂无信息");
          }
        }
      });
    await this.getFormData();
    await this.getShareInfo();

  },
  methods: {
    // 获取微信认证
    async getShareInfo() {
      let url = location.href.split("#")[0]; // 只需要传地址就好，其他后端都会写好来
      let ticketData = await this.$http.get(
        "/BO/BillList/GetWxSign?url=" + url
        // "/BO/BillList/GetWxSign?url=https://hzq.yoojet.com/"
      );
      let arr = ticketData.Data.split(",");
      const config = {
        appId: "wxde354ac424a66adc",
        timestamp: arr[2],
        nonceStr: arr[1],
        signature: arr[0],
      };
      wx.config({
        debug: false, //是否打开debug调试模式。
        appId: config.appId, // appID 公众号的唯一标识
        timestamp: config.timestamp, // 生成签名的时间戳
        nonceStr: config.nonceStr, //  生成签名的随机串
        signature: config.signature, // 生成的签名
        jsApiList: ["onMenuShareAppMessage"],
      });
      wx.ready(() => {
        var shareData = {
          title: this.ReceiptTitle || "对账函",
          desc: this.sendMessage || '请确认对账信息',
          link: location.href + "&href=1",
          imgUrl: "https://hzq.yoojet.com/hy.jpg",
          success: function (res) {
            console.log("分享好友成功");
          },
          cancel: function (err) {
            console.log(err, "错误信息");
          },
        };
        //点击要去分享
        wx.onMenuShareAppMessage(shareData);
      });
    },
    async getFormData() {
      if (this.$route.query?.href) {
        this.$http
          .post("/BO/BillList/GetBillLink", {
            // AppType: 'AppHzq'
            id:`http://hzq.yoojet.com?id=${this.billid}&SignType=3&IsPosition=0&Distance=&Type=0`
          })
          .then((result) => {
            if (result.Success) {
              console.log(result.Data,'跳转')
              this.href = result.Data;
            }
          });
      }
    },
    // 下载
    handleDownload() {
      this.$http.post("/Base/Base_Reconciliation/GetRecPDF?id=" + this.Id).then((res) => {
        if (res.Success) {
          downloadFile(res.Data, this.Id);
        } else {
          this.$message.error(res.Msg);
        }
      });
    },
    resetValue(val, itemdata) {
      console.log(itemdata[val.dataIndex])
      if (itemdata[val.dataIndex]) {
        if (val.type === "text") {
          return itemdata[val.dataIndex];
        } else if (val.type === "number") {
          return this.NumFormatting(
            itemdata[val.dataIndex],
            Number(val.decimals ? val.decimals : 2)
          );
        } else {
          return this.NumFormatting(itemdata[val.dataIndex], 2);
        }
      } else {
        return itemdata[val.dataIndex];
      }
    },
    NumFormatting(data, num = 2) {
      return NumFormatting(data, num);
    },
    getpx(num, wh, str) {
      var dpi = jsgetDPI();
      if (str == "px") {
        if (wh == "w") {
          return (num / 2.54) * dpi[0];
        }
        if (wh == "h") {
          return (num / 2.54) * dpi[1];
        }
      }
      if (str == "cm") {
        if (wh == "w") {
          return (num / dpi[0]) * 2.54;
        }
        if (wh == "h") {
          return (num / dpi[1]) * 2.54;
        }
      }
      // px转cm：cm = pxValue/dpi*2.54
      //           px = cm/2.54*dpi
    },
  },
};
</script>

<style>
#printDiv .a {
  padding: 0 20px;
  /* border-bottom: 1px solid #000; */
}

#printDiv .duanl {
  text-indent: 2em;
}

.imgBox img {
  width: 100%;
  height: 100%;
}

.printBox {
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
}

.printBox .printSon {
  padding: 10px 0;
  text-align: right;
  border-top: 1px solid #ccc;
}

th {
  width: 120px;
}
</style>